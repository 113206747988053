<template lang="html" src="./pageAdministration.template.vue"></template>

<style lang="scss" src="./pageAdministration.scss"></style>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';
const i18nData = require('./pageAdministration.i18n');

export default {
  name: 'PageAdministration',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      supplier: null,
      currentUserIsAdmin: false,
      tabs: [
        {
          name: this.$t('General'),
          route: { name: 'AdministrationGeneral' },
          icon: 'fas fa-globe',
        },
        {
          name: this.$t('Payment'),
          route: { name: 'AdministrationPayment' },
          icon: 'fas fa-credit-card',
        },
        {
          name: this.$t('UsersAndSecurity'),
          route: { name: 'AdministrationUserAndSecurity' },
          icon: 'fas fa-shield-alt',
        },
        {
          name: this.$t('Printers'),
          route: { name: 'AdministrationPrinters' },
          icon: 'fas fa-print',
        },
      ],
    };
  },
  updated() {
    if(this.$route.name === 'Administration') {
      this.$router.push(this.tabs[0].route);
    }
  },
  created() {
    /**
     * GET SUPPLIER
     */
    this.getSupplierRoles();
  },
  methods: {
    /**
     * GET SUPPLIER ROLES
     */
    getSupplierRoles() {
      this.$apiInstance.getSupplierRoles(this.$route.params.supplierUUID).then(
        (supplierRolesData) => {
          this.supplierRolesData = supplierRolesData;
          this.supplierRolesData.admin.forEach((user) => {
            if (user._id === this.$appConfig.user._id) {
              this.currentUserIsAdmin = true;
            }
          });
        },

        (error) => {
          /**
           * ERROR GET SUPPLIER ROLES
           */
          this.supplierRolesErrorMessage = ApiErrorParser.parse(error);
        }
      );
    },
  },
};
</script>
